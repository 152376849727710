'use strict'
const React = require('react')
const StaticComponent = require('../static')

class GithubSVGIcon extends StaticComponent {
  render() {
    const {size = 12} = this.props
    return (
      <svg
        style={{
          height: `${size}px`,
          width: `${size}px`,
        }}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0C2.685 0 0 2.685 0 6C0 8.655 1.7175 10.8975 4.1025 11.6925C4.4025 11.745 4.515 11.565 4.515 11.4075C4.515 11.265 4.5075 10.7925 4.5075 10.29C3 10.5675 2.61 9.9225 2.49 9.585C2.4225 9.4125 2.13 8.88 1.875 8.7375C1.665 8.625 1.365 8.3475 1.8675 8.34C2.34 8.3325 2.6775 8.775 2.79 8.955C3.33 9.8625 4.1925 9.6075 4.5375 9.45C4.59 9.06 4.7475 8.7975 4.92 8.6475C3.585 8.4975 2.19 7.98 2.19 5.685C2.19 5.0325 2.4225 4.4925 2.805 4.0725C2.745 3.9225 2.535 3.3075 2.865 2.4825C2.865 2.4825 3.3675 2.325 4.515 3.0975C4.995 2.9625 5.505 2.895 6.015 2.895C6.525 2.895 7.035 2.9625 7.515 3.0975C8.6625 2.3175 9.165 2.4825 9.165 2.4825C9.495 3.3075 9.285 3.9225 9.225 4.0725C9.6075 4.4925 9.84 5.025 9.84 5.685C9.84 7.9875 8.4375 8.4975 7.1025 8.6475C7.32 8.835 7.5075 9.195 7.5075 9.7575C7.5075 10.56 7.5 11.205 7.5 11.4075C7.5 11.565 7.6125 11.7525 7.9125 11.6925C10.2825 10.8975 12 8.6475 12 6C12 2.685 9.315 0 6 0Z"
          fill="#24292F"
        />
      </svg>
    )
  }
}

module.exports = GithubSVGIcon
